:root{
    --prim-red: #fe0000
}

a {
    color: rgba(0, 0, 0, 0.87);;
}

.header-bar{
    background-color: aliceblue !important;
}

.active-route{
    border-bottom: 2px solid var(--prim-red);
}
.header-avatar{
    color: var(--prim-red) !important;
}