.start-counter span {
    background-color: red;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    animation: blink 1.1s infinite;
}

.start-text {
    line-height: 2rem;
}

.start-text span {
    background-color: red;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.single-card{
    max-width: 280px;
    min-height: 280px;
}