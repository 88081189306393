.mAuto {
    margin: auto;
    margin-bottom: 25px !important;
}

.dot.selected{
    background: red !important;
}

.dot{
    background: black !important;
}

.carousel-status{
    color: red !important;
}

.control-arrow{
    background: red !important;
    height: 50px !important;
    margin-top: 30vh !important;
}

.carousel-container .carousel-root .carousel-slider .slider-wrapper{
    pointer-events: none !important;
}
.no-pointer-events {
    pointer-events: none;
}
  
.description{
    font-weight: 500;
    color: black !important;
}

svg{
    z-index: 999 !important;
}

.category-container{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    padding: 10px !important;
    overflow-x: auto !important;
    margin-right: 5px !important;
}

.mBadge .MuiBadge-badge{
    /* display: inline-block !important;     */
    padding: 12px 10px !important;
    background-color: transparent;
    color: red;
    border: 1px solid red;
}

.carousel-card img{
    height: auto !important;
}

.description {
    margin-top: -20px !important;
    font-size: 16px;
}

.description-single{
    font-size: 16px !important;
}

.single-post-img {
    height: auto;
}