*{
  box-sizing: border-box;
  scrollbar-color: #fe0000 #ddd;
  scrollbar-width: thin;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  color: #222;
  font-weight: normal;
  padding-right: 0 !important;
  background: url(./assets/bg/booksbg.jpg) no-repeat top center fixed;
  background-size: cover;
  font-size: 15px;
  line-height: 1.5;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow-y: hidden;
  overflow-x: hidden;  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-books{
  background-image: url(./assets/bg/booksbg.jpg);
  /* overflow-y: hidden; */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: cover;
  background-repeat: no-repeat */
  /* overflow-y: hidden;
  overflow-x: hidden;  */
}