:root {
    --blue: #1e90ff;
    --white: aliceblue;
    --transbg:rgba(255, 255, 255, 0.1);
}
.root-layout {
    height: 95vh;
    width: 550px;
    background:var(--white);
    text-align: center;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
}

.parent-root-layout {
    /* text-align: center; */
}
.website-logo{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
   
}

.website-logo >img{
    height: 250px;
}

.bottom-fixed-link{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
}

.login {
    padding: 20px 0;
    background: #000;
    display: block;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

.login > a{
    text-decoration: none;
    color: var(--white);
}

/* Portrait */
@media screen and (max-width: 480px) {
    /* CSS Rules for Phones (Portrait) */
    .root-layout{
        height: 100vh;
        width: 100vw;
    }
}

/* Landscape */
@media screen and (max-width: 768px) {
    /* CSS Rules for Phones (Landscape) */
    .root-layout{
        height: 100vh;
        width: 100vw;
    }
}

/* Portrait */
@media screen and (min-width: 481px) and (max-width: 768px) {
    /* CSS Rules for Tablets (Portrait) */
    .root-layout{
        height: 100vh;
        width: 100vw;
    }
   
}

/* Landscape */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    /* CSS Rules for Tablets (Landscape) */
   
}


/* Desktop */
@media screen and (min-width: 1025px) {
    /* CSS Rules for Desktops */
   
}

