.grid-style{
    padding: 10px;
}

.refresh-btn {
    position: fixed;
    bottom: 20px;
    right: 10px;
    border: 1px solid red;
    color: white;
    background: red;
    border-radius: 100px;
    padding: 0px 2px;    
}