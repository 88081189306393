/* Style for the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 20px;
  margin-bottom: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 20px 10px;
  text-align: center;
}

/* Style for table header */
th {
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
}

/* Style for table cells */
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* Style for alternating row colors */
tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Hover effect */
tbody tr:hover {
  background-color: #ddd;
}

.result-container{
  overflow-x: scroll;
}

.go-back-btn{
  margin-top: 14px !important;
}

.result-container{
  display: none;
}

.bg-c{
  background-color: #fff;
  padding: 20px 15px;
}

.diff-attr{
  font-size: 24px;
}

.header-attr{
  text-decoration: underline;
}
.main-img{
  margin-top: 10px;
  margin-bottom: 18px;
}