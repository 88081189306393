.login-form{
    height: 100vh;
    width: 100%;
    background: var(--white);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.radiogrp{
    margin-top: 20px !important;
}