
/* //? loader here */
.loader {
    position: absolute;
    z-index: 99999 !important;
    background: rgb();
    background-color: rgba(247, 247, 247, 1);
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}