:root{
  --green: #29bb75;
}

.quiz-container{
    text-align: center;
    margin: 10px 20px !important;
}

.answeredClass span{
  font-size: 16px !important;
  font-weight: bolder;
}

.timerContainer {
    position: relative;
    top: 0vh;
    right: 0px;
    display: flex;
    justify-content: center;
    background: var(--green);
    color: #fff;
    padding: 12px 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
  }

  .timerContainer span {
    /* background-color: rgba(255, 255, 255, 0.664); */
    /* padding: 2px 25px; */
    border-radius: 5px;
    color: red;

  }
  .questionContainer{
    background: #fff;
    display: block;
    padding: 15px;
    margin: 0 -10px 10px;
    text-align: center;
    font-size: 18px;
  }

  .options-ls{
    background-color: white;
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0px;
    border-radius: 5px;
  }

  .options-ls.selected {
    background-color: rgb(30, 31, 30);
    color: white;
  }

  .btn-holder{
    /* width: 100vw; */
    margin-top: 35px;
  }

  .btn-holder Button{
    width: 100%;
    text-align: center;
  }

  /* .options-ls :hover{
    background-color: white;
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0px;
    border-radius: 5px;
  } */

  .custom-radio-container {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
  
  .custom-radio-input {
    display: none;
  }
  
  .custom-radio {
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid #ccc;
    display: inline-block;
    position: relative;
    margin-right: 8px;
    display: none; 
    /* ?hideit */
  }
  
  .custom-radio-container input:checked + .custom-radio {
    background-color: #2196F3;
    border: 2px solid #2196F3;
  }
  
  .custom-radio-container input:checked + .custom-radio:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    background: white;
    transform: translate(-50%, -50%);
  }
  
  .custom-radio-label {
    flex: 1;
    user-select: text;
  }

  .questionContainer img{
    width: 100%;
    border-radius: 5px;
  }